import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from '@components/molecules/next-breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { filter, map, Observable } from 'rxjs';

@Component({
  selector: 'next-breadcrumb',
  templateUrl: './next-breadcrumb.component.html',
  styleUrl: './next-breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextBreadcrumbComponent implements OnInit {
  breadcrumbItems$: Observable<MenuItem[]>;

  constructor(
    private _router: Router,
    private _breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this._buildBreadcrumbOnRouterEvent();
  }

  private _buildBreadcrumbOnRouterEvent(): void {
    this.breadcrumbItems$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const built = this._breadcrumbService.buildBreadCrumb(this._router.routerState.snapshot.root);
        const lastIndex = built.length - 1;
        built[lastIndex].disabled = true;
        built[lastIndex].routerLink = undefined;
        return built;
      })
    );
  }
}
