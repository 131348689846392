import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { NextRouteProps } from '../../../core/enums/route.enum';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { TranslateService } from '@ngx-translate/core';
import { FormSectionProps } from '@components/organisms/layout/enum/form-section.enum';
import { NextRouteItem } from '../../../core/types/route.type';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  constructor(private _translateService: TranslateService) {}

  buildBreadCrumb(snapshot: ActivatedRouteSnapshot, breadCrumbList: MenuItem[] = [], urlAcc = '/'): MenuItem[] {
    const isExternal = NextObjectHelper.getPropertyFromObject(snapshot, ['data', 'isExternalPage']);
    if (isExternal) {
      return [];
    }

    const url = this._buildBreadCrumbUrl(urlAcc, snapshot.url);
    const breadcrumb = this._buildBreadCrumbItem(snapshot, url);
    if (breadcrumb.visible) {
      breadCrumbList.push(breadcrumb);
    }

    return snapshot.firstChild ? this.buildBreadCrumb(snapshot.firstChild, breadCrumbList, url) : breadCrumbList;
  }

  private _buildBreadCrumbItem(snapshot: ActivatedRouteSnapshot, url: string): MenuItem {
    const itemRouteConfig = this._getRouteItem(snapshot);

    const isFirst = !snapshot.parent;
    const hasConfig = !!snapshot.routeConfig;
    const hasUrl = !!snapshot.url.length;
    const isBreadcrumbHidden =
      snapshot.data[NextRouteProps.HIDE_BREADCRUMB_ITEM] || !!itemRouteConfig[NextRouteProps.HIDE_BREADCRUMB_ITEM];
    const visible = (!isBreadcrumbHidden && hasConfig && hasUrl) || isFirst;
    const breadcrumbItemConfig: MenuItem = {
      visible
    };

    if (visible) {
      const disabled = isFirst ? false : this._isBreadcrumbItemDisabled(snapshot, itemRouteConfig);

      const itemConfig = {
        label: this._buildLabel(snapshot),
        disabled
      };

      if (!disabled) {
        const queryParams = snapshot.data[NextRouteProps.KEEP_QUERY_PARAMS] ? snapshot.queryParams : undefined;

        const urlConfig = {
          routerLink: url,
          queryParams
        };

        Object.assign(itemConfig, urlConfig);
      }

      Object.assign(breadcrumbItemConfig, itemConfig);
    }

    return breadcrumbItemConfig;
  }

  private _isBreadcrumbItemDisabled(snapshot: ActivatedRouteSnapshot, dataRouteConfig: NextRouteItem): boolean {
    if (snapshot?.routeConfig?.path) {
      return !!dataRouteConfig[NextRouteProps.DISABLED_BREADCRUMB_ITEM];
    }
    return false;
  }

  private _buildBreadCrumbUrl(urlAcc: string, urlList: UrlSegment[] = []): string {
    return urlList.reduce((acc, urlItem) => `${acc}${urlItem.path}/`, urlAcc);
  }

  private _buildLabel(snapshot: ActivatedRouteSnapshot) {
    let labelKey;
    if (!snapshot.parent) {
      labelKey = 'NAVIGATION.HOME';
    } else {
      const itemRouteConfig = this._getRouteItem(snapshot);
      const formSectionConfig = NextObjectHelper.getPropertyFromObject(snapshot.data, [
        NextRouteProps.FORM_SECTION_CONFIG
      ]);

      if (formSectionConfig) {
        const pathParamKey = itemRouteConfig[NextRouteProps.PATH_PARAM];
        const pathParam = snapshot.params[pathParamKey!];
        let keyFromPath;
        Object.keys(formSectionConfig).forEach(configKey => {
          const config = formSectionConfig[configKey];
          if (config?.[FormSectionProps.PATH] === pathParam) {
            keyFromPath = config?.[FormSectionProps.LABEL_KEY];
          }
        });
        labelKey = NextValueHelper.defaultValue(keyFromPath, 'NAVIGATION.DEFAULT');
      } else if (itemRouteConfig.fromUrlParamPath) {
        labelKey = NextObjectHelper.getPropertyFromObject(
          snapshot,
          ['params', itemRouteConfig.pathParam],
          'NAVIGATION.DEFAULT'
        );
      } else {
        labelKey = NextValueHelper.defaultValue(itemRouteConfig.label, 'NAVIGATION.DEFAULT');
      }
    }
    return this._translateService.instant(labelKey);
  }

  private _getRouteItem(snapshot: ActivatedRouteSnapshot): NextRouteItem {
    return NextObjectHelper.getPropertyFromObject(snapshot.data, [NextRouteProps.ROUTE_ITEM_CONFIG], {});
  }
}
