import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextBreadcrumbComponent } from './next-breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NextBreadcrumbComponent],
  imports: [CommonModule, BreadcrumbModule, TranslateModule],
  exports: [NextBreadcrumbComponent]
})
export class NextBreadcrumbModule {}
